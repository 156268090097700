import React from 'react';
import {Link} from "react-router-dom";
import TeacherMenu from "./TeacherMenu";
import {Container, Divider, Grid, LinearProgress} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import {mainStyles} from "../MainStyles";

export default function TeacherModuleOverview() {
  const students = [
    {
      "id": 1,
      "firstname": "Ursa",
      "lastname": "Beach",
      "email": "lorem.ac.risus@egestasadui.co.uk",
      "tval1": 4,
      "tval2": 2,
      "tval3": 4,
      "tval4": 2,
      "sval1": 4,
      "sval2": 3,
      "sval3": 0,
      "sval4": 4
    },
    {
      "id": 2,
      "firstname": "Aquila",
      "lastname": "Cote",
      "email": "malesuada.Integer.id@enimnisl.edu",
      "tval1": 3,
      "tval2": 0,
      "tval3": 2,
      "tval4": 0,
      "sval1": 4,
      "sval2": 4,
      "sval3": 4,
      "sval4": 4
    },
    {
      "id": 3,
      "firstname": "Nehru",
      "lastname": "Dale",
      "email": "dolor.Fusce.feugiat@vehiculaetrutrum.co.uk",
      "tval1": 3,
      "tval2": 2,
      "tval3": 4,
      "tval4": 1,
      "sval1": 2,
      "sval2": 2,
      "sval3": 0,
      "sval4": 0
    },
    {
      "id": 4,
      "firstname": "Rama",
      "lastname": "Estrada",
      "email": "fringilla@velitduisemper.ca",
      "tval1": 1,
      "tval2": 4,
      "tval3": 1,
      "tval4": 0,
      "sval1": 0,
      "sval2": 0,
      "sval3": 3,
      "sval4": 4
    },
    {
      "id": 5,
      "firstname": "Kevyn",
      "lastname": "Joseph",
      "email": "Aenean.gravida.nunc@velitin.org",
      "tval1": 0,
      "tval2": 4,
      "tval3": 4,
      "tval4": 0,
      "sval1": 3,
      "sval2": 2,
      "sval3": 2,
      "sval4": 2
    },
    {
      "id": 6,
      "firstname": "Zia",
      "lastname": "Mckinney",
      "email": "ridiculus.mus.Proin@Duisrisusodio.com",
      "tval1": 3,
      "tval2": 2,
      "tval3": 4,
      "tval4": 3,
      "sval1": 3,
      "sval2": 2,
      "sval3": 0,
      "sval4": 4
    },
    {
      "id": 7,
      "firstname": "Ulysses",
      "lastname": "Guerra",
      "email": "mauris@accumsaninterdumlibero.co.uk",
      "tval1": 0,
      "tval2": 2,
      "tval3": 1,
      "tval4": 2,
      "sval1": 1,
      "sval2": 0,
      "sval3": 4,
      "sval4": 2
    },
    {
      "id": 8,
      "firstname": "Harper",
      "lastname": "Walton",
      "email": "Aenean@eu.org",
      "tval1": 2,
      "tval2": 1,
      "tval3": 4,
      "tval4": 4,
      "sval1": 2,
      "sval2": 3,
      "sval3": 3,
      "sval4": 4
    },
    {
      "id": 9,
      "firstname": "Alyssa",
      "lastname": "Pollard",
      "email": "velit.dui.semper@volutpat.edu",
      "tval1": 0,
      "tval2": 0,
      "tval3": 3,
      "tval4": 2,
      "sval1": 1,
      "sval2": 0,
      "sval3": 2,
      "sval4": 3
    },
    {
      "id": 10,
      "firstname": "Russell",
      "lastname": "Pena",
      "email": "nulla.vulputate@at.org",
      "tval1": 1,
      "tval2": 1,
      "tval3": 2,
      "tval4": 4,
      "sval1": 3,
      "sval2": 3,
      "sval3": 2,
      "sval4": 3
    },
  ];
  const styles = {
    dot: {
      display: 'inline-block',
      width: 35, height: 34,
      paddingLeft: 13, paddingTop: 14,
      backgroundColor: '#4051b6',
      borderRadius: '50%',
      color: 'white',
    },
    row: {marginTop: 26, marginBottom: 5}
  }
  const initials = (s) => s.firstname.substring(0, 1) + s.lastname.substring(0, 1)

  return (
    <Container fluid style={mainStyles.containerBasic}>

      <TeacherMenu />

      <Grid container spacing={3}>
        <Grid item md={12} style={styles.row}></Grid>
      </Grid>

      <Grid container style={styles.row} spacing={2}>
        <Grid item md={5}>Name / E-Mail</Grid>
        <Grid item md={5} className="d-none d-md-block">Bewertung</Grid>
        <Grid item md={1}></Grid>
        <Grid item md={1}></Grid>
      </Grid>

      {students.map(s => (
        <Grid container key={s.id} style={styles.row} spacing={2}>
          <Grid item xs={1}><span style={styles.dot}>{initials(s)}</span></Grid>
          <Grid item xs={4}>
            {s.firstname + " " + s.lastname}
            <br/>
            <small>{s.email}</small>
          </Grid>
          <Grid item xs={5}>
            <LinearProgress variant="buffer" value={50} valueBuffer={100}/>
            <br/>
            <LinearProgress variant="buffer" value={40} valueBuffer={100} color="secondary"/>
          </Grid>
          <Grid item xs={1}>
            <Link to="/teacherMemberGrades">grades</Link>
          </Grid>
          <Grid item xs={1}>
            <MenuIcon />
          </Grid>
        </Grid>)
      )}

      <Grid container>
        <Grid item>
          <Link to="/team" className="btn btn-outline-primary" style={{marginTop: 20}}>zurück</Link>
        </Grid>
      </Grid>
    </Container>
  )
}



