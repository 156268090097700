

export const mainStyles = {
  container: {
    paddingTop: 10
  },
  containerBasic: {
    padding: 20
  },
  mainButton: {
    marginRight: 10,
    marginBottom: 10
  },
  text: {
    empty: {
      color: '#cb1010' // TODO: MainColor
    }
  }
}