import React from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import PageEnter from "./PageEnter";
import PageLogout from "./PageLogout";
import TeacherModuleOverview from "./teacherpages/TeacherModuleOverview";
import TeacherMemberGrade from "./teacherpages/TeacherMemberGrade";
import TeacherModuleEvaluation from "./teacherpages/TeacherModuleEvaluation";
import TeacherHome from "./teacherpages/TeacherHome";
import {Container, Grid} from "@material-ui/core";
import CompetenceGridAdmin from "./competenceGrids/CompetenceGridAdmin";
import PageAuthentication from "./PageAuthentication";


export default function App() {
  return (
    <React.Fragment>
      <Container fluid>
        <Router>
          <Switch>
            <Route exact path="/">
              <p>clip interactive GmbH - Teams Trial (Startklar 31.8.2021 16:30)</p>
              <hr/>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/enter">enter</Link></li>
                <li><Link to="/team">team</Link></li>
              </ul>

              <Grid container >
                <Grid item xs={10}>
                  <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                  <PageAuthentication /> {/* TODO: test authentication*/}
                </Grid>
              </Grid>

            </Route>
            <Route path="/enter"><PageEnter/></Route>
            <Route path="/logout"><PageLogout/></Route>

            <Route path="/team"><TeacherHome/></Route>

            <Route path="/teacherModuleOverview"><TeacherModuleOverview/></Route>
            <Route path="/teacherMemberGrades"><TeacherMemberGrade/></Route>
            <Route path="/teacherEvaluation"><TeacherModuleEvaluation/></Route>

            <Route path="/competenceGridAdmin"><CompetenceGridAdmin/></Route>
          </Switch>

        </Router></Container>
    </React.Fragment>
  )
}

