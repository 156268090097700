import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {Button, Container, Grid, LinearProgress} from "@material-ui/core";
import PageAuthentication from "../PageAuthentication";
import {mainStyles} from "../MainStyles";
import TeacherMenu from "./TeacherMenu";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

const styles = {
  row: {
    marginTop: 20, marginBottom: 10
  },
  ratingsRow: {
    backgroundColor: '#fff',
    paddingTop: 24, paddingRight: 10, paddingBottom: 20, paddingLeft:16,
    marginTop: 20,
    borderRadius: 10,
    fontSize: '0.9em',
  },
  ratingsRowLink: {
    color: 'black',
    textDecoration: 'none'
  },
  ratingsText: {
    fontSize: '0.9em',
    bold: true
  }
}

export default function TeacherHome() {
  const history = useHistory();
  const handleModuleOverview = () => history.push("/teacherModuleOverview")
  const handleNewRating = () => history.push("/team")
  const handleCompetenceGridAdmin = () => history.push("/competenceGridAdmin")

  return (
    <Container fluid style={mainStyles.containerBasic}>

      <TeacherMenu />

      <Grid container style={styles.ratingsRow}>
        <Grid item xs={9} onClick={handleModuleOverview} style={{cursor: 'pointer'}}>
          <span style={styles.ratingsRowLink}>
            Modul 152 Multimediaelemente für Web implementieren
          </span>
          <div style={{marginTop:10, width: '80%'}}>
            <small>Stand der Bewertung</small>
            <LinearProgress variant="buffer" value={50} valueBuffer={100} style={{marginTop:6}}/>
            <br/>
            <LinearProgress variant="buffer" value={40} valueBuffer={100} color="secondary"/>
          </div>
        </Grid>
        <Grid item xs={2} spacing={2}>
          <small>Notenschnitt:</small><br/><span style={{display:"inline-block", fontSize: '1.5em', marginTop:10}}>4.7</span>
        </Grid>
        <Grid item xs={1} spacing={1}>
          <SettingsOutlinedIcon style={{ color: 'gray'}}/>
        </Grid>
      </Grid>

      <br/><br/><br/><br/><br/><br/><br/><br/>
      <Grid container>
        <Grid item xs={12}>
          <hr/>
        </Grid>
      </Grid>

      <Grid container spacing={10} style={mainStyles.container}>
        <Grid item>
          <Button
            variant="contained" color="primary"
            spacing={1} style={mainStyles.mainButton}
            onClick={handleNewRating}>
            erstellen
          </Button>
        </Grid>
      </Grid>

    </Container>
  )
}

