import React, {useState} from 'react'
import {Grid} from "@material-ui/core";

const styles = {
}

function Folder({open, children, title}) {
  const [isOpen, setIsOpen] = useState(open);
  return (
    <React.Fragment>
      <Grid container style={styles.row}>
        <Grid item>
          <div onClick={ () => setIsOpen(!isOpen)}>
            { isOpen
              ? <img src={"images/arrow-open.png"} alt="open"/>
              : <img src={"images/arrow-closed.png"} alt="closed"/>
            }
            <span style={styles.ratingsText}>{title}</span>
          </div>
        </Grid>
      </Grid>
      { isOpen && children }
    </React.Fragment>
  )
}

export default Folder;