import React, {useState, useEffect} from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import * as msal from "@azure/msal-browser";
import * as PropTypes from "prop-types";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
/*
const graphConfig = {
  graphEndpoint: "https://graph.microsoft.com/v1.0",
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphMailEndpoint: "https://graph.microsoft.com/v1.0/me/messages",
  graphGroupMembersEndpoint: "https://graph.microsoft.com/v1.0/groups/e150402f-bffc-4284-b3ef-989da91dea94/members"
};*/

microsoftTeams.initialize(); // TODO: Dieser Code dürfte nur einmal ausgelöst werden

function Alert(props) {
  return (<div style={{backgroundColor: "#ff8888", padding: 20, borderRadius: 15}}>
    {props.children}
  </div>)
}

export default function PageAuthentication() {
  // eslint-disable-next-line no-undef
  const [username, setUsername] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [displayName, setDisplayName] = useState("");
  const [profileId, setProfileId] = useState("");

  const [group, setGroup] = useState({ displayName: "", id:"", description:"" });
  const [owners, setOwners] = useState([]);
  const [members, setMembers] = useState([]);

  const msalConfig = {
    auth: {
      clientId: "9f092298-9cff-42c4-98ba-5ab12c7e456f",
      authority: "https://login.microsoftonline.com/common",
      redirectUri: "https://tr.clip.ch",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Info:
              console.info(message);
              return;
            case msal.LogLevel.Verbose:
              console.debug(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
          }
        }
      }
    }
  }
  const myMSALObj = new msal.PublicClientApplication(msalConfig);
  const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphGroupsEndpoint: "https://graph.microsoft.com/v1.0/groups/"
  }
  const loginRequest = {
    scopes: ["User.Read", "Group.Read.All"]
  };
  const tokenRequest = {
    scopes: ["User.Read", "Group.Read.All"],
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
  };

  function selectAccount() {
    console.log("selectAccount...")
    const currentAccounts = myMSALObj.getAllAccounts();

    if (currentAccounts.length === 0) {
      setErrorMsg("selectAccount: " + "currentAccounts.length==0")
      return;
    } else if (currentAccounts.length > 1) {
      // Add choose account code here
      setErrorMsg("selectAccount: " + "Multiple accounts detected.")
      console.warn("Multiple accounts detected.");
    } else if (currentAccounts.length === 1) {
      setUsername(currentAccounts[0].username)
      //showWelcomeMessage(username);
    }
  }
  useEffect(() => selectAccount(), [])
  function handleResponse(response) {
    if (response !== null) {
      setUsername(response.account.username)
      //showWelcomeMessage(username);
    } else {
      selectAccount();
    }
  }
  function signIn() {
    myMSALObj.loginPopup(loginRequest)
      .then(handleResponse)
      .catch(error => {
        console.error(error);
        setErrorMsg("signIn:" + error)
      });
  }
  function getTokenPopup(request) {
    request.account = myMSALObj.getAccountByUsername(username);

    return myMSALObj.acquireTokenSilent(request)
      .catch(error => {
        console.warn("silent token acquisition fails. acquiring token using popup");
        if (error instanceof msal.InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return myMSALObj.acquireTokenPopup(request)
            .then(tokenResponse => {
              console.log(tokenResponse);
              return tokenResponse;
            }).catch(error => {
              console.error(error);
            });
        } else {
          console.warn(error);
        }
      });
  }
  const updateUI = (data, endpoint) => {
    console.log("updateUI")
    console.log(data)
    console.log(endpoint)
  }
  function seeProfile() {
    getTokenPopup(loginRequest)
      .then(response => {
        callMSGraph(graphConfig.graphMeEndpoint, response.accessToken, (data, endpoint) => {
          console.log(endpoint)
          setDisplayName(data.displayName)
          setProfileId(data.id)
        });
      }).catch(error => {
      console.error(error);
    });
  }
  function readCurrentGroup() {
    let contextGet = microsoftTeams.getContext(function (context) {
      getTokenPopup(tokenRequest)
        .then(response => {
          callMSGraph(graphConfig.graphGroupsEndpoint + context.groupId, response.accessToken,(data, endpoint) => {
            console.log(endpoint)
            setGroup({ displayName: data.displayName, id: data.id, description: data.description })
          });
        }).catch(error => {
        console.error(error);
      });
    });
  }
  function readOwners() {
    let contextGet = microsoftTeams.getContext(function (context) {
      getTokenPopup(tokenRequest)
        .then(response => {
          callMSGraph(graphConfig.graphGroupsEndpoint + context.groupId + "/owners", response.accessToken,
            (data, endpoint) => {
              console.log(endpoint)
              setMembers(data.value)
            });
        }).catch(error => {
        console.error(error);
      });
    });
  }
  function readMembers() {
    let contextGet = microsoftTeams.getContext(function (context) {
      getTokenPopup(tokenRequest)
        .then(response => {
          callMSGraph(graphConfig.graphGroupsEndpoint + context.groupId + "/members", response.accessToken,
            (data, endpoint) => {
            console.log(endpoint)
            setMembers(data.value)
          });
        }).catch(error => {
        console.error(error);
      });
    });
  }
  function callMSGraph(endpoint, token, callback) {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers
    };

    console.log('request made to Graph API at: ' + new Date().toString());

    fetch(endpoint, options)
      .then(response => response.json())
      .then(response => callback(response, endpoint))
      .catch(error => console.log(error));
  }

  return (
    <React.Fragment>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography >Authentication Data (Graph API)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            { errorMsg.length>0 &&
            <Alert severity="error">{errorMsg}</Alert>
            }
            <br/>
            <button onClick={ signIn }>sign in</button>
            <button onClick={ seeProfile }>profile</button>
            <button onClick={ readCurrentGroup }>current group</button>
            <button onClick={ readOwners }>owners</button>
            <button onClick={ readMembers }>members</button>
            <br/>
            <p>Username: {username}</p>
            <br/>
            <h2>Profile</h2>
            <p>Display name: {displayName}</p>
            <p>Id: {profileId}</p>
            <br/>
            <h2>Group data</h2>
            <p>Display name: {group.displayName}</p>
            <p>Id: {group.id}</p>
            <p>Description: {group.description}</p>
            <br/>
            <h2>Owners and Members</h2>
            { owners.map((o,idx) => <div key={idx}>
              <p>Id: {o.id} / { o.userPrincipalName }</p>
            </div>)}
            { members.map((m,idx) => <div key={idx}>
              <p>Id: {m.id} / { m.userPrincipalName }</p>
            </div>)}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  )
}

export function PageAuthentication1() {

  // Anleitung zum Ablauf der Authentisierung
  // https://docs.microsoft.com/en-us/graph/auth-v2-user

  // Azure Active Directory Graph API
  // https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-graph-api

  // Azure Portal
  // https://portal.azure.com/?l=en.en-gb#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Authentication/appId/9f092298-9cff-42c4-98ba-5ab12c7e456f/objectId/bd5ed597-f32d-4421-9ae4-4fa27619edb0/isMSAApp//defaultBlade/Overview/appSignInAudience/AzureADandPersonalMicrosoftAccount/servicePrincipalCreated/true

  // Anleitung für die Authentisierung
  // https://medium.com/praval-jain/react-app-authentication-with-msal-js-a5b09b7ceb4e


    //microsoftTeams.settings.setValidityState(true);
/*
    this._agent = new Msal.UserAgentApplication(
      authconfig.B2CAPPID,
      'https://'+ authconfig.B2CTENANT + '.b2clogin.com/tfp/' + authConfig.B2CTENANT + '.onmicrosoft.com/${REDIRECT_POLICY}',
      'https://${authconfig.B2CTENANT}.b2clogin.com/tfp/${authConfig.B2CTENANT}.onmicrosoft.com/${REDIRECT_POLICY}',
      this._onTokenCallback,
      {
        redirectUri: '${window.location.origin}/authend',
        navicateToLoginRequestUrl, false,
        validateAuthority: false
      }
    )
  }*/


  const authenticate = () => {
    const authConfig = {
      B2CAPPID : '9f092298-9cff-42c4-98ba-5ab12c7e456f',
      B2CTENANT : 'ee2a4b58-2594-4a70-bed2-fe1175df9141',
      B2COBJECTID : 'bd5ed597-f32d-4421-9ae4-4fa27619edb0',
      TENANT: 'ee2a4b58-2594-4a70-bed2-fe1175df9141'
    }
    microsoftTeams.getContext( (context) => {
      console.log(">>> tid: " + context.tid)
      console.log(">>> groupID: " + context.groupId)
      console.log(">>> loginHint: " + context.loginHint)
      console.log(">>> userTeamRole: " + context.userTeamRole)

      let authTokenRequest = {
          successCallback: (clientSideToken) => {
            console.log(">>> Success (clientSideToken): " + clientSideToken)

            let scopes = ["https://graph.microsoft.com/User.Read"]
            fetch('https://clip-cf30-prototype-backend.herokuapp.com/api/auth/getUserAccessToken', {
              method: 'get',
              headers: {
                "Content-Type": "application/text",
                "Authorization": "Bearer " + clientSideToken
              },
              cache: 'default'
            })
              .then((response) => {
                if (response.ok) {
                  return response.text();
                } else {
                  console.log("Error getting Server-Side-Token")
                  response.text().then(errorText => console.log(errorText))
                }
              })
              .then((token) => {
                console.log(token)
                //let accessToken = token;
                //getUserInfo(context.userPrincipalName);
                /*
                if (IsValidJSONString(responseJson)) {
                  if (JSON.parse(responseJson).error)
                    reject(JSON.parse(responseJson).error);
                } else if (responseJson) {
                  accessToken = responseJson;
                  getUserInfo(context.userPrincipalName);
                }*/
              });




            // ee2a4b58-2594-4a70-bed2-fe1175df9141
            // fetch("https://login.microsoftonline.com/"+authConfig.TENANT+"/oauth2/v2.0/token", {
            //     "method": "POST",
            //     "headers": {
            //       "Content-Type": "application/x-www-form-urlencoded"
            //   },
            //   body: 'grant_type=authorization_code&' +
            //     'client_id='+authConfig.B2CAPPID + "&" +
            //     'assertion=' + authToken
            //     /*
            //       grant_type: 'authorization_code',
            //       scope: 'https://graph.microsoft.com/mail.read',
            //       redirect_uri: 'https://tr.clip.ch/authend',
            //       client_secret: '4nL7sV0q4O-lRGYG0d_~T793N-_ROp2N~N',
            //       code: authToken*/
            //   })
            //   .then(response => response.json())
            //   .then(data => console.log(data))
            },
          failureCallback: (error) => {
            console.log(">>> Failure: " + error);
            },
      };
      microsoftTeams.authentication.getAuthToken(authTokenRequest);
    })
  }

  return (
    <React.Fragment>
      <p>Authentication Page</p>
      <button onClick={ authenticate }>authenticate</button>
    </React.Fragment>
  )
}

