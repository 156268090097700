import React from 'react';
import * as microsoftTeams from "@microsoft/teams-js";

export default function PageEnter() {

    // If you are not using the MS Teams web SDK, you can remove this entire if block, otherwise if you want theme
    // changes in the MS Teams client to propogate to the page, you should leave this here.
    //if (this.inTeams()) {
    microsoftTeams.initialize(); // TODO: Dieser Code dürfte nur einmal ausgelöst werden

    microsoftTeams.settings.setValidityState(true);

    microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
      microsoftTeams.settings.setSettings({
        suggestedDisplayName: "Kompetenzen",
        entityId: "clip-tr",
        websiteUrl: "https://tr.clip.ch",
        contentUrl: "https://tr.clip.ch/team",
        removeUrl: ""
      });
      saveEvent.notifySuccess();
    });

    // tr.clip.ch -> blinde Seite, oder Standard Info Seite
    // tr.clip.ch/enter -> im Manifest angegeben -> Startseite
    // tr.clip.ch/team -> contentUrl -> Liste der Lernende
    return (
      <p>Enter Page</p>
    )
}