import React from 'react';
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { PageAuthentication1 } from "../PageAuthentication";

const styles = {
  container: { marginTop: 20, marginBottom: 10},
  link: { fontSize: '0.95em', marginRight: 20, textDecoration: 'none'},
  linkActive: { color: '#3f51b5', borderBottom: '2.5px solid #3f51b5', paddingBottom: 4 },  // TODO: use MainColor
  icon: { color: 'gray'},
  separator: { borderTop: '1px solid #ccc', marginTop: 30 }
}

export default function TeacherMenu() {
  return (
    <React.Fragment>
      <Grid container style={{paddingTop:16}}>
        <Grid item xs={6} lg={6}>
          <Link to="/team" style={{...styles.link,...styles.linkActive}}>Zugewiesen</Link>
          <Link to="/team" style={styles.link}>Abgeschlossen</Link>
          <Link to="/team" style={styles.link}>Entwürfe</Link>
        </Grid>
        <Grid item xs={3} lg={3} ></Grid>
        <Grid container xs={2} lg={2} >
          <Link to="/team" style={styles.link}>Meine Kompetenzraster</Link>
        </Grid>
        <Grid container xs={1} lg={1} justifyContent="flex-end">
          <SettingsOutlinedIcon style={styles.icon}/>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <hr style={styles.separator}/>
          <PageAuthentication1 /> {/* TODO: test authentication*/}
        </Grid>
      </Grid>

    </React.Fragment>
  )
}

