import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {Button, Container, Grid} from "@material-ui/core";
import Folder from '../components/Folder'
import {mainStyles} from "../MainStyles";

const styles = {
  competenceFrame: {
    marginTop: 10,
    paddingBottom: 20
  }
}

export default function CompetenceGridAdmin() {
  const handleCreatCompetenceGrid = () => {}
  return (
    <Container fluid style={mainStyles.containerBasic}>

      <Grid container style={styles.competenceFrame}>
        <Grid item xs={12}>
          <h1>meine Kompetenzraster</h1>
          <span style={mainStyles.text.empty}>
            Keine offiziellen Kompetenzraster verfügbar
          </span>
        </Grid>
      </Grid>
      <Grid container style={styles.competenceFrame}>
        <Grid item xs={12}>
          <Button
            variant="contained" color="primary"
            spacing={1} style={mainStyles.mainButton}
            onClick={handleCreatCompetenceGrid}>
            Neues Kompetenzraster erstellen
          </Button>
        </Grid>
      </Grid>


      <Grid container style={styles.competenceFrame}>
        <Grid item xs={12}>
          <Link to={"/team"}>zurück</Link>
        </Grid>
      </Grid>

    </Container>
  )
}

