import React from 'react';
import {Container, Grid} from "@material-ui/core";
import TeacherMenu from "./TeacherMenu";

const styles = {
}

export default function TeacherModuleEvaluation() {
  return (
    <Container fluid>

      <TeacherMenu
        title={"Bewertung - Modul 152 Multimediaelemente für Web implementieren"}
        subtitle={""}
      />

      <Grid row>
        <Grid col xl={6} sx={12}>
          <img src={"/imagesPrototype/evaluationspider.png"} className={"img-fluid"} alt="spider"/>
        </Grid>
      </Grid>
      <Grid row>
        <Grid col>
          Handlungsziele<br/>
          HZ 1 - Vorgegebene Funktionalität im Rahmen eines Softwareprojekts mit einer agilen Methode umsetzen.<br/>
          HZ 2 - Funktionalitäten schrittweise mit Praktiken der agilen Entwicklung in vorgegebenen Release-Zyklen realisieren, testen und den Software-Release in Kurzform präsentieren.<br/>
          HZ 3 - Bestehende Entwurfsmuster und/oder geprüfte Softwarekomponenten zur Lösung von Problemen gezielt einsetzen.<br/>
          HZ 4 - Die Ergebnisse und das Arbeitsvorgehen eines Release-Zyklus reflektieren und daraus Erkenntnisse für das weitere Vorgehen ableiten.<br/>
          HZ 5 - Die Projektdokumente und den Programmquelltext in einem gemeinsamen Versionsverwaltungssystem bereitstellen.<br/>
          HZ 6 - Programmquelltext nach Konventionen intuitiv verständlich formulieren und bei Bedarf überarbeiten.<br/>
        </Grid>
      </Grid>
    </Container>
  )
}
