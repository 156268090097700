import React, { useState } from 'react';
import { Link } from "react-router-dom";
import {Button, ButtonGroup, Container, Grid, Tooltip} from "@material-ui/core";
import TeacherMenu from "./TeacherMenu";

export default function TeacherMemberGrade({member}) {
  return (
    <Container fluid>
      <Grid row>
        <Grid col>
          <UserCompetencies member={member}/>
        </Grid>
      </Grid>
    </Container>
  )
}

function UserCompetencies({member}) {
  const grades = [
    {
      id: 1,
      name: 'Vorgegebene Funktionalität im Rahmen eines Softwareprojekts mit einer agilen Methode umsetzen.',
      competencies: [
        {
          id: 11,
          name: 'Ich kann den Inhalt der vier Leitsätze und der zwölf Prinzipien des agilen Manifests sinngemäss wiedergeben',
          valueTeacher: 2, valueStudent: 3
        },
        {
          id: 12,
          name: 'Ich kann aufzeigen, wie und wo das agile Manifest mit seinen vier Leitsätze und der zwölf Prinzipien im praktischen Einsatz von Scrum zum Ausdruck kommt.',
          valueTeacher: 1, valueStudent: 1
        },
        {
          id: 13,
          name: 'Ich kann erläutern wie Scrum, dessen Artefakte (Product Backlog, Sprint Backlog, User-Story, Task), Ereignisse (Pre-Planing (Grooming), ',
          valueTeacher: 4, valueStudent: 4
        }
      ]
    },
    {
      id: 2,
      name: 'Funktionalitäten schrittweise mit Praktiken der agilen Entwicklung in vorgegebenen Release-Zyklen realisieren, testen und den Software-Release in Kurzform präsentieren.',
      competencies: [
        {
          id: 21,
          name: 'Maecenas sed diam eget risus varius blandit sit amet non magna.',
          valueTeacher: 3, valueStudent: 3
        },
        {
          id: 22,
          name: 'Cras justo odio, dapibus ac facilisis in, egestas eget quam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.',
          valueTeacher: 1, valueStudent: 1
        },
        {
          id: 23,
          name: 'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.',
          valueTeacher: 2, valueStudent: 2
        },
        {
          id: 24,
          name: 'Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
          valueTeacher: 1, valueStudent: 2
        }
      ]
    }
  ]
  const styles = {
    dot: {
      height: 40, width: 40,
      backgroundColor: '#00F',
      borderRadius: '50%',
      display: 'inline-block',
      paddingLeft: 9, paddingTop: 7,
      color: 'white',
    },
    row: { marginBottom: 20},
    gradeRow: { marginTop: 3, marginBottom: 16},
    rowCompetencies: { marginTop: 10, marginBottom: 0},
  }

  return (
    <Container fluid>

      <TeacherMenu
        title={"Bewertung - Modul 152 Multimediaelemente für Web implementieren"}
        subtitle={"Ursa Beach"}
      />

      <Grid container>
        <Grid item lg={12} style={{paddingtop: 10, paddingBottom: 10}}>
          <h2>Bewertung der Kompetenzen im Modul 152</h2>
          { /*<strong>{member.firstname + " " + member.lastname}</strong> */ }
        </Grid>
      </Grid>

      { grades.map(g => (
        <Grid container key={g.id} style={styles.row}>
          <Grid item md={12}><strong>{g.id})&nbsp;{g.name}</strong></Grid>
          { g.competencies.map(c => (
            <React.Fragment key={c.id}>
              <Grid col xl={12} sx={12}>
                <Grid container style={styles.rowCompetencies}>
                  <Grid item xl={12} sx={12}>{ c.name}</Grid>
                </Grid>
                <Grid container style={styles.gradeRow} spacing={2}>
                  <Grid item xl={3} sx={3}><ThreeButtonGrade grade={c.valueTeacher} type="primary"/></Grid>
                  <Grid item xl={3} sx={3}><ThreeButtonGrade value={c.valueStudent} type="secondary"/></Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>)
      )}
      <Grid container>
        <Grid item>
          <Link to="/teacherModuleOverview" className="btn btn-outline-primary" style={{marginTop: 20}}>zurück</Link>
        </Grid>
      </Grid>
    </Container>
  )
}


function ThreeButtonGrade({grade, type}) {
  const [value, setValue] = useState(grade)
  const setGroupValue = (grade) => setValue( grade===value ? 0 : grade)
  const btType = (grade) => value<grade ? "outlined" : "contained"

  return (
    <ButtonGroup variant="contained" color={type} size="small" aria-label="primary button group">
      { [ 1, 2, 3].map(grade =>
        <Tooltip title="unterkompetenz gemäss 1/2/3">
          <Button variant={btType(grade)} onClick={() =>setGroupValue(grade)}>{grade}</Button>
        </Tooltip>
      )}
    </ButtonGroup>
  )
}